@import '../../styles/variables';

.wrapper {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  background-position: center;
  padding: 90px 0;
  color: white;
  width: 100%;
  .registerContainer {
    width: 550px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 40rem;
    margin: 0 auto;
    .logoDiv {
      padding: 1rem 0rem;
      .logo {
        height: 4rem;
        margin: auto;
      }
    }
    .submitBtn {
      margin-top: 1rem;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background-color: $primaryColor;
      color: white;
      cursor: pointer;
      outline: none;
      border: 0;
      font-size: 18px;
      background: linear-gradient(
        90deg,
        rgba(146, 110, 244, 1) 0%,
        rgba(73, 139, 245, 1) 41%,
        rgba(114, 163, 241, 1) 100%
      );
    }
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .googleBtn {
      margin-top: 10px;
      background-color: white;
      height: 40px;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 2px solid $lightGray;
      .googleImg {
        width: 30px;
        padding-right: 10px;
      }
    }
  }
}

.phoneInputCSS {
  margin-bottom: 2rem;
  margin-top: 2rem;
  & > div {
    background-color: transparent !important;
    width: 12%;
    padding-left: 0.5rem;
    & > div:nth-child(2) {
      width: 45px;
      height: 30px;
      background-color: $lightGray;
    }
    & > div:nth-child(3) {
      color: black;
    }
  }
  & > input {
    color: black;
    border: 1px solid $lightGray;
    border-radius: 5px;
    height: 2rem;
    &:focus {
      outline: 2px solid $lightGray;
    }
  }
}
.displayName {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    .registerContainer {
      width: 80%;
    }
    .authDiv {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 380px) {
  .wrapper {
    .registerContainer {
      .logoDiv {
        padding: unset;
        .logo {
          height: 2.5rem;
        }
      }
    }
    .authDiv {
      .googleBtn {
        font-size: 15px;
      }
    }
  }
}
