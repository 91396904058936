@import '../../styles/variables';

.invitesWrapper {
  .invite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    span {
      color: $waikawa-grey;
      margin-right: 0.5rem;
    }
    span:first-of-type {
      font-weight: 600;
    }
    .btnsWrapper {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      .btnAccept {
        background: $primaryColor;
        height: 30px;
        font-size: $font-size-normal;
      }
      .btnReject {
        background: $black-russian;
        height: 30px;
        font-size: $font-size-normal;
      }
      .info {
        width: 24px;
        height: 24px;
        cursor: pointer;
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}
