@import '../../styles/variables';

.wrapper {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  background-position: center;
  padding: 110px 0;
  color: white;
  width: 100%;
  .registerContainer {
    width: 550px;
    width: 40rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 25px;
    .confirmMsg {
      color: black;
    }
    .errorMsg {
      color: $red-orange;
      font-size: $font-size-sm;
      margin-bottom: 0.5rem;
    }
    .logoDiv {
      padding: 1rem 0rem;
      .logo {
        height: 4rem;
        margin: auto;
      }
    }
    .confirmDiv {
      display: flex;
      flex-direction: column;
      & > {
        .inputLogin {
          margin-bottom: 1rem;
        }
      }
      .submitBtn {
        margin-top: 1rem;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: $primaryColor;
        color: white;
        cursor: pointer;
        outline: none;
        border: 0;
        font-size: 18px;
        margin-bottom: 10px;
        background: linear-gradient(
          90deg,
          rgba(146, 110, 244, 1) 0%,
          rgba(73, 139, 245, 1) 41%,
          rgba(114, 163, 241, 1) 100%
        );
      }
      .linkToRegister {
        text-decoration: none;
        color: $lightBlue;
        text-align: start;
        margin-top: 10px;
      }
      .displayName {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .wrapper {
    .registerContainer {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 380px) {
  .wrapper {
    .registerContainer {
      .confirmMsg {
        font-size: 12px;
      }
      .logoDiv {
        padding: unset;
        .logo {
          height: 3rem;
        }
      }
    }
  }
}
