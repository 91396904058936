@import '../../styles/variables';
.accountWrapper {
  .heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .userInfoWrapper {
    .infoItem {
      display: flex;
      justify-content: space-between;
      .infoItemLeft {
        display: flex;
        width: 200px;
        span {
          margin-right: 0.5rem;
          color: $waikawa-grey;
          font-weight: 600;
        }
      }
      .infoItemRight {
        display: flex;
        flex: 1;
        span {
          color: $waikawa-grey;
        }
      }
    }

    .link {
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        color: $lightBlue;
      }
    }
  }
  .groupWraper {
    .groups {
      display: flex;
      margin-left: 0.3rem;
      align-items: center;
      border-bottom: 1px solid $waikawa-grey;
      padding: 0.5rem 0;
      span {
        color: $waikawa-grey;
      }
      span:first-of-type {
        font-weight: 600;
      }
    }
    .warning {
      color: $red-orange;
    }
  }
  .presenceBtnWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .displayName {
    margin-top: 0.5rem;
  }
  .editIcon {
    cursor: pointer;
    margin-left: 0.3rem;
    fill: $waikawa-grey;
  }
  .displayNameBtn {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .accountWrapper {
    .userInfoWrapper {
      .infoItem {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .infoItemLeft {
          display: unset;
          width: unset;
        }
      }
    }
  }
}
