$white: #ffffff;
$black: #000000;
$lightGray: #dfdfdf;
$black-russian: #090d28;
$black-russian-opacity: rgba(9, 13, 40, 0.65);
$grayish-navy: #181c3a;
$grayish-navy-opacity: rgba(24, 28, 58, 0.65);
$waikawa-grey: #646b9b;
$blue-violet: #8d29ff;
$blue-primary: #027ffe;
$pastel-green: #00c84a;
$fun-green: #048533;
$zuccini: #075a26;
$sunsent-orange: #ff5136;
$red-orange: #ff2626;
$yellow-turbo: #ffd21c;
$turquoise: #2ee9d0;
$midnight-express: #131734;
$Bali-Hai: #8f9bb3;
$lightBlue: #007fff;
$profileBlue: #c9faff;
$blueButton: #0dcaf0;
$primaryColor: #498bf5;
$secondaryColor: #926ef4;
$inviteColor: #64a4ec;
$presence-busy: #f80000;
$presence-online: #8bc900;
$presence-offline: #838383;
$icon-color: #7c7c7c;
$lightBg: #eff8ff;
$grayBg: #eaf6f7;
$redWithOpacity: rgba(255, 0, 0, 0.4);
$bg-primary: $black-russian;
$bg-secondary: $grayish-navy;
$bg-secondary-opacity: $grayish-navy-opacity;
$bg-secondary-darker: $midnight-express;
$bg-colored: $pastel-green;
$bg-violet: $blue-violet;

$color-primary: $white;
$color-secondary: $pastel-green;
$color-neutral: $waikawa-grey;
$color-violet: $blue-violet;
$color-text: $Bali-Hai;
// $color-text: $white;

$link: $waikawa-grey;
$link-hover: $pastel-green;
$link-active: $pastel-green;

$btn-primary: $pastel-green;
$btn-primary-shadow: $fun-green;
$btn-primary-active: $zuccini;
$btn-primary-color: $white;
$btn-secondary: $waikawa-grey;
$btn-secondary-color: $white;
$btn-dark: $bg-secondary;
$btn-black: $bg-primary;
$btn-bright: $blue-violet;

$input-bg: $black-russian;
$input-color: $white;
$input-placeholder: $waikawa-grey;
$input-active-border: $blue-violet;
$text-color-gray: #aeaaae;
$text-color-gray-second: #909090;
$incomming-call-bg: #374249;

$font-primary: 'Arial';
$font-primary-bold: 'Arial';
$font-secondary: 'Arial-Black';

$font-size-extra-sm: 0.6rem;
$font-size-nomral-sm: 0.7rem;
$font-size-sm: 0.8rem;
$font-size-normal: 0.9rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.7rem;

$font-weight-normal: 700;
