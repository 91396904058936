@import '../../styles/variables';

.sideBarWrapper {
  // height: 100%;
  // overflow-y: auto;
  width: 100%;
  display: flex;
  .link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $black-russian;
    width: 20%;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    cursor: pointer;
    & > svg {
      width: 25px;
      height: 25px;
    }
    &:hover {
      background-color: aliceblue;
      transition: 0.3s ease-in-out;
    }
    span {
      display: inline-flex;
      font-size: 0.7rem;
      padding-top: 0.5rem;
    }
  }
  .active {
    @extend .link;
    & > svg {
      fill: $lightBlue;
    }
    span {
      color: $lightBlue;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 668px) {
  .globalWrapper {
    .sideBarWrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .link,
      .active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        gap: 5px;
        max-height: 60px;
        font-size: 0.9rem;
        padding: 0;
        width: 100%;
      }
    }
  }
}
