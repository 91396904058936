@import '../../styles/variables';

.msg {
  :global {
    .rce-mbox-right {
      background: $lightGray;
    }
    .rce-mtlink {
      min-width: 100px;
    }
    svg {
      display: none;
    }
    img {
      width: 100%;
    }
  }
}

.roomNameTooltip {
  z-index: 1;
  max-width: calc(100% - 12%);
}

.chatWrapper {
  position: relative;
  height: calc(100vh - 60px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  .chatHeader {
    height: 50px;
    background-color: aliceblue;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    padding: 0 1rem;
    .roomName {
      word-break: break-all;
      .online {
        color: $presence-online;
        font-size: $font-size-sm;
      }

      .busy {
        @extend .online;
        color: $presence-busy;
      }

      .dnd {
        @extend .online;
        color: $presence-busy;
      }
      .offline {
        @extend .online;
        color: $presence-offline;
      }
    }
    .callIconWrapper {
      display: flex;
      color: fun-green;
      span {
        cursor: pointer;
      }
      .callIcons {
        margin-left: 0.5rem;
        cursor: pointer;
        height: 20px;
        width: 20px;
        transition: 0.3s;
      }
      .callIcons:hover {
        color: $blue-primary;
      }
    }
  }
  .chatMessages {
    padding: 1rem;
    overflow-y: auto;
    height: calc(100vh - 200px);
    flex: 1;
    .msgWrapper {
      position: relative;
    }
  }
  .divider {
    display: flex;
    justify-content: center;
    span {
      display: inline-flex;
      font-size: $font-size-sm;
      padding: 1rem 0.5rem;
      color: $waikawa-grey;
    }
  }
  .redactedMessage {
    display: flex;
    justify-content: flex-end;
    padding: 0.2rem;
    color: $waikawa-grey;
    span {
      font-size: $font-size-sm;
      margin-left: 4px;
    }
  }
  .chatInput {
    min-height: 40px;
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
    max-height: 150px;
    padding: 10px;
    .inputFile {
      color: $icon-color;
      margin-right: 0.5rem;
      cursor: pointer;
      input {
        display: none;
        visibility: none;
      }
    }
    .inputBtns {
      display: flex;
      justify-content: space-around;
      button {
        background: transparent;
        border: none;
        color: $blue-primary;
        cursor: pointer;
      }
    }
    .inputArea {
      width: 100%;
      margin: 0 0.5rem;
      & textarea {
        resize: none;
        width: 100%;
        min-width: 0;
        min-height: 100%;
        outline: none;
        border: none;
        font-size: $font-size-normal;
        &::placeholder {
          color: $icon-color;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 668px) {
  .chatWrapper {
    height: calc(100vh - 200px);
    background: white;
    .chatMessages {
      height: calc(100vh - 319px);
    }
    .chatInput {
      display: flex;
    }
  }
}

.popover {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 10, 20, 0.1);
  border: 1px solid #ddd;
  font-size: 90%;
  padding: 8px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 200px;
  max-width: calc(100vw - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  z-index: 2;
  outline: none;
  -webkit-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  .itemWrapper {
    cursor: pointer;
    display: flex;
    gap: 0.3rem;
    justify-content: space-between;
  }
}
