@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  .defaultInput {
    color: black;
    border: 1px solid $lightGray;
    border-radius: 5px;
    height: 2rem;
    text-align: center;
    &:focus {
      outline: 2px solid $lightGray;
    }
  }
}
