@import '../../styles/variables';

.callWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  .call {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .icon {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
    .onHoldWrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      .onHold {
        display: flex;
        background-color: #3742491a;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1px;
        .name,
        .holdBnt {
          display: inline-flex;
          padding: 0.3rem 1rem;
          font-size: $font-size-sm;
        }
        .name {
          font-weight: 700;
        }
      }
    }
    .contactInfo {
      display: flex;
      width: 300px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
      img {
        width: 60px;
        height: 60px;
      }
      .name {
        font-size: $font-size-l;
        font-weight: 700;
        text-align: center;
      }
      .callingInfo {
        color: $text-color-gray;
        font-size: $font-size-sm;
      }
    }
    .btnWrapper {
      margin: 1.5rem 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 230px;
      gap: 1rem;
    }
    .callBtns {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: end;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

@media only screen and (max-width: 680px) {
  .callWrapper {
    .btnWrapper {
      margin-top: 50px;
    }
    .callBtns {
      margin-top: 20px;
    }
  }
}
