@import '../../styles/variables';

.callWrapper {
  padding: 10px;
  align-items: center;
  gap: 10px;
  color: #000;
  margin: 0px 5px;
  &:hover {
    background-color: $profileBlue;
    border-radius: 5px;
  }
  .callStart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      font-size: 11px;
      color: $waikawa-grey;
    }
  }
  .callMade {
    border-radius: 10px;
    display: flex;
    padding: 0.3rem 0.5rem;
    gap: 0.2rem;
    background: $grayBg;
    cursor: pointer;
  }
  .missedCall {
    border-radius: 10px;
    display: flex;
    padding: 0.3rem 0.5rem;
    gap: 0.2rem;
    background: $redWithOpacity;
  }
  .call {
    display: flex;
    position: relative;
    align-items: center;
    gap: 0.2rem;
    margin: 0.3rem 0;
    cursor: pointer;
    span {
      display: inline-flex;
    }
    .contact {
      margin-left: 0.5rem;
      font-size: $font-size-sm;
      font-weight: 500;
    }
    .callRecordingIcon {
      position: absolute;
      right: 0;
      font-size: $font-size-sm;
    }
  }
  .callRecordings {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    margin-top: 0.4rem;
    .record {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .recordIcon {
        cursor: pointer;
      }
    }
  }
  .callDuration {
    display: flex;
    justify-content: flex-start;
    span {
      font-size: 11px;
      color: $waikawa-grey;
    }
  }
  .callsIcon {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .call {
    margin: 5px;
    &:hover {
      background-color: $profileBlue;
      border-radius: 5px;
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      span {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .msg {
        font-size: 12px;
      }
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
