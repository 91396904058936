@import '../../styles/variables';

.wrapper {
  .customBtn {
    background: $pastel-green;
    color: $white;
    border-radius: 3px;
    padding: 0 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    height: 25px;
    font-size: $font-size-sm;
    &:hover {
      background: $presence-online;
    }
  }

  padding: 3px;
  h2 {
    text-align: center;
    margin: 0.5rem 0;
  }
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    span {
      color: $pastel-green;
      font-size: $font-size-l;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 1s;
  }
  .extensionsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
    .registerDiv {
      display: flex;
      .orbDiv {
        width: 10px;
        height: 10px;
        background-color: $pastel-green;
        border-radius: 50%;
        transform: translate(-3px, 4px);
      }
      .orbDivOff {
        @extend .orbDiv;
        background-color: $presence-busy;
      }
    }
    .extensions {
      position: relative;
      .extensionList {
        position: absolute;
        top: 32px;
        right: 0;
        background-color: $white;
        padding: 0.3rem 0.5rem;
        min-width: 150px;
        background: $white;
        -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        div {
          padding: 0.3rem 0.5rem;
          display: flex;
        }
      }
      .extensionBtn {
        background-color: $pastel-green;
        color: $white;
        border-radius: 3px;
        padding: 0.3rem 0.5rem;
        outline: none;
        border: none;
        cursor: pointer;
        &:hover {
          background-color: $presence-online;
        }
      }
    }
    span {
      color: $pastel-green;
    }
    .warning {
      color: $red-orange;
    }
  }
  .dialerWrapper {
    width: 100%;
    border-radius: 20px;
    padding-top: 10px;

    .inputBox {
      width: 100;
      display: flex;
      margin-bottom: 10px;
      .inputDialer {
        width: 93%;
        height: 40px;
        background-color: $lightGray;
        outline: none;
        border: 0;
        margin: auto;
        border-radius: 20px;
        text-align: center;
      }
    }
    .blfBtnsWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.8rem;
      margin-bottom: 10px;
      flex-wrap: wrap;
      gap: 2px;
      & > div {
        width: 24%;
      }
      .blfBtn {
        @extend .customBtn;
        background: $primaryColor;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: $lightBlue;
        }
      }
      .selectedBlfBtn {
        @extend .customBtn;
        background: #96ce19;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: #96ce19;
        }
      }
      .emptySlot {
        @extend .customBtn;
        background: lightgray;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: lightgrey;
        }
      }
      .onHold {
        @extend .customBtn;
        background: $primaryColor;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: $lightBlue;
        }
      }
      .inCall {
        @extend .customBtn;
        background: green;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: lightseagreen;
        }
      }
    }
    .buttonBox {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        display: inline-block;
        width: 31%;
        background: transparent;
        border: none;
        color: black;
        height: 70px;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        font-size: 30px;
        border: 1px solid $lightGray;
        &:hover {
          background-color: $lightGray;
          color: black;
        }
      }
      .deleteBtn,
      .asterisk {
        width: 46.5%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        &:hover {
          background-color: $lightGray;
        }
      }
      .asterisk {
        position: relative;
        span {
          position: absolute;
          top: 17px;
        }
      }
    }
  }
  .makeCallWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    cursor: pointer;
    gap: 2rem;
    flex-wrap: wrap;
    .icon {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
  .callBtnWrapper {
    display: flex;
    justify-content: center;
    height: calc(4 * 90px);
    border: 1px solid $lightGray;
    .callBtnBox {
      @extend .makeCallWrapper;
      max-width: 240px;
      z-index: 999999999;
      .icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}

.transferCallWrapper {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 40px;
  .closeTransferCall {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .closeIcon {
      cursor: pointer;
    }
  }
  .callContactsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
    overflow: hidden;
    .callContactsHeader {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      span {
        display: inline-flex;
        justify-self: flex-end;
        cursor: pointer;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
      .searchInputWrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: $white;
        padding: 0 0.3rem;
        .searchForm {
          display: flex;
        }
        .input {
          flex-grow: 1;
          border: none;
          outline: none;
          font-size: 16px;
          padding: 4px;
        }
        .icon {
          width: 20px;
          height: 20px;
          color: $icon-color;
        }
      }
    }
  }
}
