@import '../../styles/variables';

.switchWrapper {
  display: flex;
  align-items: center;
  font-size: $font-size-normal;
  span {
    display: inline-flex;
    margin-right: 0.5rem;
  }
}
