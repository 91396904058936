@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem 0;
  height: 70vh;
  position: relative;
  .callContactsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
    overflow: hidden;
    .callContactsHeader {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.38);
      span {
        display: inline-flex;
        justify-self: flex-end;
        cursor: pointer;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
      .searchInputWrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: $white;
        padding: 0 0.3rem;
        .searchForm {
          display: flex;
        }
        .input {
          flex-grow: 1;
          border: none;
          outline: none;
          font-size: 16px;
          padding: 4px;
        }
        .icon {
          width: 20px;
          height: 20px;
          color: $icon-color;
        }
      }
    }
  }
  .icon {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
    .callingInfo {
      margin-top: 0.5rem;
      font-size: $font-size-sm;
    }
    .name {
      font-size: $font-size-xl;
      font-weight: 700;
    }
  }
  .btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    gap: 1rem;
    .newCallBtns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .answerBtn {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
