@import '../../styles/variables';
@import '../../styles//mixins.scss';

.callBoxWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    padding: 0.5rem;
    background-color: $lightGray;
    border-radius: 50%;
  }
  .callMade {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
    gap: 0.2rem;
    background: $grayBg;
  }
  .missedCall {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
    gap: 0.2rem;
    background: $redWithOpacity;
  }
  .call {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    width: 100%;
    justify-content: center;
    span {
      display: inline-flex;
      font-size: $font-size-normal;
    }
    .duration {
      font-size: 11px;
      color: $waikawa-grey;
    }
  }

  .date {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    color: $waikawa-grey;
  }
}

.callIcon {
  width: 25px;
}

.videoCallIcon {
  width: 30px;
}
.callIconMiss {
  width: 20px;
}