@import '../../styles/variables';

.msgBoxWrapper {
  display: flex;
  padding: 1rem;
  .image {
    max-height: 100%;
    max-width: 100%;
    padding: 0.3rem 0;
  }
  .imageTooltip {
    font-size: $font-size-normal;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    padding: 0.3rem 0.5rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    min-width: 60px;
  }
  .right {
    display: flex;
    flex-direction: column;
    background: $lightBg;
    padding: 0.3rem 1rem 0.3rem 0.5rem;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-box-shadow: -4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    box-shadow: -4px 4px 9px 0px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    min-width: 60px;
  }
  .sender,
  .date {
    font-size: 11px;
    color: $waikawa-grey;
  }
  .sender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .downloadIcon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      &:hover {
        color: $blue-primary;
      }
    }
  }
  .date {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
  }
}
