@import '../../styles/variables';
@import '../../styles//mixins.scss';

.removedMessage {
  display: flex;
  padding: 1rem;
  .left {
    display: flex;
    align-items: flex-start;
    background: $grayBg;
    padding: 0.3rem 0.5rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowLeft;
    max-width: 400px;
    span {
      font-size: $font-size-sm;
      color: $waikawa-grey;
      opacity: 0.6;
    }
  }
  .right {
    display: flex;
    align-items: flex-end;
    background: $profileBlue;
    padding: 0.3rem 1rem 0.3rem 0.5rem;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowRight;
    max-width: 400px;
    span {
      font-size: $font-size-sm;
      color: $waikawa-grey;
      opacity: 0.6;
    }
  }
  .icon {
    color: $waikawa-grey;
    opacity: 0.6;
  }
}
