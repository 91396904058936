@import '../../styles/variables';
@import '../../styles/mixins.scss';

.videoBoxWrapper {
  display: flex;
  padding: 1rem;
  // margin: 1rem 0;
  .playerWrapper {
    position: relative;
    padding-top: 56.25%;
    .video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .videTooltip {
    font-size: $font-size-normal;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    padding: 0 0.5rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowLeft;
    width: 300px;
  }
  .right {
    display: flex;
    flex-direction: column;
    background: $lightBg;
    padding: 0rem 1rem 0rem 0.5rem;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowRight;
    width: 300px;
  }
  .sender,
  .date {
    font-size: 11px;
    color: $waikawa-grey;
    margin: 0.3rem 0;
  }
  .sender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.3rem;
    .downloadIcon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      &:hover {
        color: $blue-primary;
      }
    }
  }
  .date {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
  }
}
