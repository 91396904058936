@import '../../styles/variables';

.roomActive {
  background-color: $profileBlue;
}

.tootlip {
  z-index: 1;
}

.room {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  margin: 1px 5px;
  cursor: pointer;
  &:hover {
    background-color: $profileBlue;
    border-radius: 5px;
  }
  .imgWrapper {
    position: relative;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
    .online {
      width: 10px;
      height: 10px;
      background-color: $presence-online;
      border-radius: 50%;
      position: absolute;
      left: 36px;
      top: 0px;
      cursor: pointer;
    }

    .busy {
      @extend .online;
      background-color: $presence-busy;
    }

    .dnd {
      @extend .online;
      background-color: $presence-busy;
    }
    .offline {
      @extend .online;
      background-color: $presence-offline;
    }
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 7px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .favorite,
      .favoriteActive,
      .name {
        cursor: pointer;
        font-size: $font-size-normal;
        font-weight: 500;
      }
      .favoriteActive {
        color: $blue-primary;
      }
      .msg,
      .dateMsg {
        font-size: 11px;
        color: $waikawa-grey;
      }
      .notificationCounts {
        font-size: 9px;
        width: 11px;
        height: 11px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $pastel-green;
        border-radius: 50%;
        padding: 2px;
        color: $white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    margin: 5px;
    &:hover {
      background-color: $profileBlue;
      border-radius: 5px;
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      span {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .msg {
        font-size: 12px;
      }
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
