@mixin boxShadowLeft {
  -webkit-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
}

@mixin boxShadowRight {
  -webkit-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.15);
}
