@import '../../styles/variables';
.callsWrapper {
  height: calc(100vh - 220px);
  overflow: auto;
  margin-top: .5rem;
  .divider {
    display: flex;
    justify-content: center;
    margin: 0.3rem 0;
    span {
      font-size: $font-size-sm;
      color: $waikawa-grey;
    }
  }
}

.noData {
  height: calc(100vh - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
}
