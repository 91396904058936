@import '../../styles/variables';
.wrapper {
  height: calc(100vh - 220px);
  overflow: auto;
  .noFavorites {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: $font-size-normal;
  }
}
