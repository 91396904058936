/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f9bb3;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f9bb3;
  border-radius: 10px;
  border: 0px solid black;
}

.toast {
  z-index: 999999999 !important;
  margin-right: 10px;
}

.toast-wrapper {
  border-radius: 6px;
  padding-left: 13px;
  font-family: 'Arial';
  font-size: medium;
}
