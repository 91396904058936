@import '../../styles/variables';

.addToChatWrapper {
  .error {
    font-size: $font-size-sm;
    color: $red-orange;
  }
  .roomName {
    margin-bottom: 0.5rem;
    .inputName {
      text-align: left;
      input {
        padding-left: 1rem;
      }
    }
  }
  .btnsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .leftBtns {
      display: flex;
    }
    .close,
    .clear,
    .add {
      background: $white;
      color: $black-russian;
      font-size: $font-size-normal;
    }
    .close {
      background: $red-orange;
      color: $white;
    }
    .add {
      background-color: $primaryColor;
      color: $white;
    }
    .add:disabled {
      background-color: $primaryColor;
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
}
