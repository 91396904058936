@import '../../styles/variables';

.roomListWrapper {
  height: calc(100vh - 220px);
  overflow-y: hidden;
  .creatChatBtns {
    display: flex;
    gap: 0.3rem;
    border-top: 1px solid $profileBlue;
    border-bottom: 1px solid $profileBlue;
    height: 30px;
    button {
      display: flex;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      padding: 0.3rem;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      font-size: $font-size-normal;
      .icon {
        width: 16px;
        height: 16px;
        margin-left: 0.3rem;
        cursor: pointer;
        fill: black;
      }
    }

    button:hover {
      .icon {
        fill: #007fff;
      }
    }
  }
  .roomList {
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
