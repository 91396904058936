@import '../../styles/variables';

.iconWrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  span {
    color: $text-color-gray-second;
    font-size: $font-size-nomral-sm;
  }
}
