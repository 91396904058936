@import '../../styles/variables';
@import '../../styles//mixins.scss';

.msgBoxWrapper {
  display: flex;
  padding: 1rem;
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    padding: 0.3rem 0.5rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowLeft;
    max-width: 400px;
    min-width: 60px;
    word-break: break-word;
  }
  .right {
    display: flex;
    flex-direction: column;
    background: $lightBg;
    padding: 0.3rem 1rem 0.3rem 0.5rem;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    @include boxShadowRight;
    max-width: 400px;
    min-width: 60px;
    word-break: break-word;
  }
  .sender,
  .date {
    font-size: 11px;
    color: $waikawa-grey;
  }
  .message {
    font-size: $font-size-normal;
    margin: 0.4rem 0;
  }
  .date {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
  }
}
