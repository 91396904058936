@import '../../styles/variables';

.wrapper {
  .duration {
    display: flex;
    justify-content: center;
    color: $inviteColor;
    span {
      font-size: $font-size-sm !important;
    }
  }
  .incomingCall {
    .incomingInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 150px;
        height: 150px;
      }
      span {
        color: $inviteColor;
      }
    }
    .incomingIconBox {
      display: flex;
      justify-content: center;
      .call {
        width: 23px;
        height: 23px;
        margin-right: 10px;
        transform: translateY(3px);
        cursor: pointer;
        fill: white;
        background-color: $pastel-green;
        border-radius: 50%;
        padding: 3px;
      }
      .endCall {
        @extend .call;
        background-color: red;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    img {
      width: 150px;
      height: 150px;
    }
    span {
      color: $inviteColor;
      margin-top: 1rem;
      font-size: 25px;
    }
  }
  .callInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $inviteColor;
    margin-bottom: 1rem;
    .number {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 20px;
    }
    .iconBox {
      display: flex;
      flex-direction: row;
      .video {
        width: 35px;
        height: 35px;
        cursor: pointer;
        fill: black;
      }
      .call {
        width: 23px;
        height: 23px;
        margin-right: 10px;
        transform: translateY(3px);
        cursor: pointer;
        fill: white;
        background-color: $pastel-green;
        border-radius: 50%;
        padding: 3px;
      }
      .endCall {
        @extend .call;
        background-color: red;
      }
      .message {
        width: 25px;
        height: 25px;
        margin-left: 12px;
        transform: translateY(5px);
        cursor: pointer;
        fill: black;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    span {
      font-size: 20px;
      color: $inviteColor;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 380px) {
  .wrapper {
    .body {
      img {
        width: 100px;
        height: 100px;
      }
      span {
        font-size: 20px;
      }
    }
    .callInfo {
      .number {
        font-size: 15px;
      }
      .iconBox {
        .call {
          width: 25px;
          height: 25px;
        }
        .video {
          width: 31px;
          height: 31px;
        }
      }
    }
    .footer {
      span {
        font-size: 15px;
      }
    }
  }
}
