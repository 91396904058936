// .appWrapper {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   .appToolbar {
//     width: 100%;
//     background-color: #350d36;
//     height: 44px;
//     color: white;
//   }
//   .homeWrapper {
//     display: flex;
//     width: 100%;
//     background-color: lightblue;

//     .sidibarOrganizationWrapper {
//       background-color: black;
//       height: 100%;
//       color: white;
//       width: 60px;
//       min-width: 60px;
//     }
//     .mainContentWrapper {
//       display: flex;
//       flex-direction: column;
//       width: 100%;
//     }
//   }
// }

@import '../../styles/variables';

.appWrapper {
  height: 100vh;
  width: 100%;
  .appToolbar {
    display: flex;
    height: 60px;
    background-color: black;
    color: $white;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    img {
      height: 30px;
    }
    .searchInputWrapper {
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: $white;
      padding: 0 0.3rem;
      .searchForm {
        display: flex;
      }
      .input {
        flex-grow: 1;
        border: none;
        outline: none;
        font-size: 16px;
        padding: 4px;
      }
      .icon {
        width: 20px;
        height: 20px;
        color: $icon-color;
      }
    }
  }
  .homeWrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    .profileDiv {
      height: 100px;
    }
    .sidibarOrganizationWrapper {
      width: 320px;
      border-right: 1px solid $lightGray;
      min-width: 320px;
    }
    .mainContentWrapper {
      width: calc(100% - 240px);
      height: 100%;
      overflow-y: hidden;
      display: flex;
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 668px) {
  .appWrapper {
    .appToolbar {
      img {
        height: 15px;
      }
    }
    .searchInputWrapper {
      .input {
        width: 120px;
        font-size: 12px;
      }
    }
    .homeWrapper {
      flex-direction: column;
      .sidibarOrganizationWrapper {
        flex-direction: row;
        height: 160px;
        justify-content: space-around;
        width: 100%;
        border-right: unset;
      }
      .mainContentWrapper {
        width: 100%;
        height: unset;
        overflow-y: unset;
      }
    }
  }
}
