@import '../../styles/variables';

.twilio {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.test {
  width: 40px;
  height: 40px;
}

// Define base styles for participant divs
.participant {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
}

.videoContainer {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $grayish-navy;

  .videoWrapper {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    max-height: 100%;
    justify-content: center;
    &.sharingScreen {
      .sharingVideoContainer {
        display: flex;
        width: 100%;
        height: calc(100vh - 100px);
        order: 1;
        border-radius: 0.5rem;
        align-items: flex-start;
        video {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          border-radius: 0.5rem;
        }
      }
      .rcp {
        order: 2;
        display: flex;
        gap: 0.5rem;
        width: 30%;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        height: calc(100vh - 100px);
        & > video {
          max-height: 150px;
        }
      }
    }

    .sharingVideoContainer {
      display: flex;
      width: 100%;
      height: calc(100vh - 100px);
      border-radius: 0.5rem;
      order: 1;
      video {
        max-width: 100%;
        border-radius: 0.5rem;
      }
    }
    .lcp,
    .rcp {
      order: 2;
      display: flex;
      gap: 0.5rem;
      width: 100%;
      height: calc(100vh - 100px);
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      max-width: 800px;
      & > div {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
  .shareBtns {
    position: absolute;
    bottom: 70px;
    width: 300px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 30;
  }
  .btns {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    z-index: 30;
  }
}

.showMic {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor;

  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 30;
  border: 0;
  & > svg {
    width: 25px;
    height: 25px;
    fill: white;
  }
}

.shareLink {
  @extend .showMic;
  background-color: $lightGray;
}

.endCall {
  @extend .showMic;
  background-color: red;
}

.shareScreen {
  @extend .showMic;
  background-color: $lightGray;
}

.hideMic {
  @extend .showMic;
  background-color: $lightGray;
  display: none;
  & > svg {
    width: 25px;
    height: 25px;
    fill: white;
  }
}

.showCam {
  @extend .showMic;
  background-color: $primaryColor;
  left: 60%;
}

.infoBtn {
  @extend .showMic;
  background-color: $primaryColor;
}

.hideCam {
  @extend .showMic;
  display: none;
  background-color: $lightGray;
  & > svg {
    width: 25px;
    height: 25px;
    fill: white;
  }
}
.showMicOnlyAudio {
  display: none;
  background-color: $primaryColor;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 30;
  border: 0;
  & > svg {
    width: 35px;
    height: 35px;
    fill: white;
  }
}
.hideMicOnlyAudio {
  @extend .showMic;
  display: block;
  background-color: $lightGray;

  & > svg {
    width: 35px;
    height: 35px;
    fill: white;
  }
}

.notificationWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 350px;
  border: 1px solid $inviteColor;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  background-color: white;
  span {
    color: $inviteColor;
  }
  img {
    width: 150px;
    height: 150px;
  }
  h3 {
    text-align: center;
    color: $inviteColor;
  }
  .notifBtn {
    display: flex;
    gap: 30px;
    align-items: center;
    .incomingCall {
      background-color: $pastel-green;
      fill: white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      padding: 5px;
      cursor: pointer;
    }
    .incomingCallEnd {
      @extend .incomingCall;
      background-color: red;
    }
    .call {
      background-color: $pastel-green;
      fill: white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      padding: 5px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 667px) {
  .wrapper {
    & > div {
      .videoLocal {
        width: 115px;
      }
    }
    .shareBtns {
      width: 300px;
      bottom: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      z-index: 31;
    }
    .btns {
      gap: 20px;
      justify-content: unset;
      bottom: 20px;
      left: 20px;
      transform: unset;
      width: 300px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .notificationWrapper {
    width: 270px;
    height: 250px;
    img {
      height: 100px;
      width: 100px;
    }
    .notifBtn {
      .incomingCall,
      .call {
        width: 25px;
        height: 25px;
      }
    }
  }
  .showMicOnlyAudio {
    width: 25px;
    height: 25px;
    & > svg {
      width: 15px !important;
      height: 15px !important;
      transform: translate(0px, 1px);
    }
  }

  .hideMicOnlyAudio {
    & > svg {
      width: 15px !important;
      height: 15px !important;
      transform: translate(0px, 1px);
      fill: white;
    }
  }
  .endCall {
    fill: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 5px;
    cursor: pointer;
    background-color: red;
    & > svg {
      width: 25px;
      height: 25px;
    }
  }
  .showMic {
    width: 25px;
    height: 25px;
    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .showCam {
    @extend .showMic;
  }
}

.customRight {
  position: absolute;
  right: 20px;
}

.infoButton {
  position: absolute;
  bottom: 20px;
  width: 25%;
  height: 250px;
  right: 20px;
  z-index: 30;
  background-color: $lightGray;
  border-radius: 5px;
  display: none;
  flex-direction: column;
  padding-top: 10px;
  padding: 10px;
  .callInformation {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    height: 100%;
    .url {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      .urlLink {
        color: $primaryColor;
        overflow: auto;
        height: 32px;
      }
      .shareLink {
        background-color: $primaryColor;
        width: 25px;
        height: 25px;
        margin-left: 10px;
        transform: translateY(6px);
      }
    }
    .elapsedTime {
      @extend .url;
      .duration {
        padding-top: 5px;
        color: $inviteColor;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }
    }
    .identity {
      display: flex;
      flex-direction: column;
      height: 100%;
      .participantIdentityDiv {
        height: 90%;
        overflow: auto;
        background-color: $Bali-Hai;
        border-radius: 5px;
        margin-top: 10px;
        .participantIdentity {
          color: $white;
          font-weight: $font-weight-normal;
          padding: 5px;
          display: flex;
          flex-direction: column;
          padding-left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 667px) {
  .infoButton {
    width: 40%;
    height: 150px;
    .callInformation {
      font-size: $font-size-extra-sm;
      .url {
        padding-bottom: 5px;
        .shareLink {
          width: 15px;
          height: 15px;
          margin-left: 7px;
          transform: translateY(0px);
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
      .elapsedTime {
        padding-bottom: 5px;
        .duration {
          font-size: $font-size-extra-sm;
        }
      }
    }
  }
}
