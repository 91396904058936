@import '../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  background: $profileBlue;
  height: 50px;
  padding: 10px;
  position: relative;
  justify-content: space-between;
  color: $lightBlue;
  font-weight: 500;
  height: 60px;
  border-bottom: 1px solid $lightGray;
  .logo {
    font-weight: bold;
  }
  .settingsBtn {
    background: transparent;
    color: $black;
    &:hover {
      color: $primaryColor;
    }
  }
  .settingsWrapper {
    width: 98%;
    position: absolute;
    background: $white;
    border-radius: 0.2rem;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
    height: 170px;
    z-index: 1;
    right: 5px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    padding-top: 1rem;
    .settingsItem {
      color: #000;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      a {
        text-decoration: none;
        color: #000;
      }
      &:hover {
        color: $primaryColor;
        a {
          color: $primaryColor;
        }
      }
    }
  }
  .settingsIcon {
    color: $black;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: $primaryColor;
    }
  }
  .userDiv {
    display: flex;
    gap: 10px;
    position: relative;
    .statusDiv {
      padding: 5px 10px;
      width: 140px;
      height: 80px;
      border-radius: 0.2rem;
      position: absolute;
      left: 40px;
      top: 38px;
      display: flex;
      flex-direction: column;
      background-color: white;
      justify-content: space-evenly;
      -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
      .checkStatus {
        display: flex;
        align-items: center;
        color: black;
        font-size: 13px;
        .checkIcon {
          color: $pastel-green;
        }
        span {
          display: inline-flex;
          cursor: pointer;
        }
        img {
          width: 12px;
          height: 12px;
          margin-right: 15px;
          margin-left: 10px;
          background-color: transparent;
        }
        .activeSpanColor {
          background-color: green;
        }
      }
    }
    .online {
      width: 10px;
      height: 10px;
      background-color: $presence-online;
      border-radius: 50%;
      position: absolute;
      left: 36px;
      top: 30px;
    }

    .busy {
      @extend .online;
      background-color: $presence-busy;
    }

    .dnd {
      @extend .online;
      background-color: $presence-busy;
    }
    .offline {
      @extend .online;
      background-color: $presence-offline;
    }
    .statusInfo {
      padding-top: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 5px;
      .status {
        cursor: pointer;
        font-size: 10px;
      }
    }
    img {
      background-color: white;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .logoutBtn {
    background: $primaryColor;
    height: 25px;
    font-size: 12px;
  }
}
