@import '../../styles/variables.scss';
.withoutPlanWrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue-violet;
    padding: 0.3rem 0.5rem;
    outline: none;
    border: none;
    color: $white;
    cursor: pointer;
    &:hover {
      background: $blue-primary;
    }
  }
  .leftSide {
    width: 55%;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      text-align: center;
      margin: 1.5rem;
    }
    div {
      padding: 0.5rem;
      a {
        text-decoration: none;
      }
    }
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100vh;
    background-color: $black-russian;
    div {
      background: $white;
      border-radius: 50%;
      height: 300px;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .withoutPlanWrapper {
    flex-direction: column;
    .leftSide {
      width: 100%;
    }
    .rightSide {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 368px) {
  .withoutPlanWrapper {
    flex-direction: column;
    .leftSide {
      width: 100%;
    }
    .rightSide {
      width: 100%;
      div {
        width: 200px;
        height: 200px;
        .logo {
          height: 40px;
        }
      }
    }
  }
}
