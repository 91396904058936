@import '../../styles/variables';

.wrapper {
  .primaryBtn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: $primaryColor;
    color: white;
    cursor: pointer;
    outline: none;
    border: 0;
    font-size: 18px;
    background: linear-gradient(
      90deg,
      rgba(146, 110, 244, 1) 0%,
      rgba(73, 139, 245, 1) 41%,
      rgba(114, 163, 241, 1) 100%
    );
  }
}
