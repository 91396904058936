@import '../../styles/variables';

.contact {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  margin: 0px 5px;
  cursor: pointer;
  &:hover {
    background-color: $profileBlue;
    border-radius: 5px;
  }
  .avatarWrapper {
    position: relative;
    .avatarImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
    }
    .noPresence {
      width: 10px;
      height: 10px;
      background-color: $waikawa-grey;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: bold;
      color: white;
      border-radius: 50%;
      position: absolute;
      left: 36px;
      top: 0px;
      cursor: pointer;
    }
    .online {
      width: 10px;
      height: 10px;
      background-color: $presence-online;
      border-radius: 50%;
      position: absolute;
      left: 36px;
      top: 0px;
      cursor: pointer;
    }

    .busy {
      @extend .online;
      background-color: $presence-busy;
    }

    .dnd {
      @extend .online;
      background-color: $presence-busy;
    }
    .offline {
      @extend .online;
      background-color: $presence-offline;
    }
  }
  .contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .airtouchContacts {
      display: flex;
      .logoDiv {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        transform: translateY(-8px);
        margin-left: 7px;
      }
    }
    .organizationNameDiv {
      .organizationName {
        font-size: $font-size-nomral-sm;
        color: $waikawa-grey;
        margin-top: 5px;
      }
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .favorite,
      .favoriteActive,
      .name {
        cursor: pointer;
        font-size: $font-size-normal;
      }
      .favoriteActive {
        color: $blue-primary;
      }
      span {
        font-size: 18px;
        font-weight: 500;
      }
      .inviteBtn {
        font-size: 11px;
        background: $inviteColor;
        height: 28px;
        border-radius: 0;
        margin-top: 0px;
        padding: 0px 10px;
        border-radius: 5px;
      }
      .msg {
        font-size: 12px;
        color: #000;
        padding-top: 8px;
      }
      .dateMsg {
        @extend .msg;
        font-size: 11px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    margin: 5px;
    &:hover {
      background-color: $profileBlue;
      border-radius: 5px;
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      span {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .msg {
        font-size: 12px;
      }
    }
    .avatarImg {
      width: 50px;
      height: 50px;
    }
  }
}
