.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  color: #616161;
  font-size: 16px;

  &:hover {
    color: #1976d2;
  }
}

.checkbox {
  appearance: none;
  border: 2px solid #bdbdbd;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:checked {
    border-color: #1976d2;
    background-color: #1976d2;
  }
}
